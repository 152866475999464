import { FrameDynamicIcon, FrameFlexibleIcon, FrameOmniChannelIcon, FrameResponsiveSupportIcon } from "assests/Home";
import { Link } from "react-router-dom";

export const subSectionsContent = [
    {
        wrapper: "0.1",
        frameIcon: <FrameDynamicIcon />,
        className: "sec-dynamic-underwriting",
        headingTitle: "Dynamic Underwriting",
        content: (
            <>
                We welcome merchants of all types from B2B to eCommerce and retail to special risk. Among high-risk payment processing companies, our tools are perfectly suited to the challenges of your industry.
            </>
        )
    },
    {
        wrapper: "0.2",
        frameIcon: <FrameFlexibleIcon />,
        className: "sec-flexible-tech",
        headingTitle: "Flexible Technology",
        content: (
            <>
                As a merchant services company, we make it easy for you to take advantage of the latest technology, so that your business is always able to keep pace with the needs of your clients
            </>
        )
    },
    {
        wrapper: "0.3",
        frameIcon: <FrameOmniChannelIcon />,
        className: "sec-omni-channel-payments",
        headingTitle: "Omni-Channel Payments",
        content: (
            <>
                Whether online, in-person or on-the-go, we offer payment solutions for every channel. Our payment processing company simplifies the process of setting up mobile, point of sale, value-added solutions and integration
            </>
        )
    },
    {
        wrapper: "0.4",
        frameIcon: <FrameResponsiveSupportIcon />,
        className: "sec-responsive-support",
        headingTitle: "Responsive Support",
        content: (
            <>
                We're a payments technology company who puts humans first, with support when you need it. You can count on us for real time support on the phone or online, with a detailed <Link to='#' className='faq-link'>FAQ</Link> and individual support.
            </>
        )
    },
];