import { MerchantIntegrationIcon, MerchantSecureDataExchangeIcon, MerchantTunnelGatewayIcon } from "assests/Home";

export const subSectionsIntegratePayment = [
    {
        wrapper: "0.0.1",
        frameIcon: <MerchantSecureDataExchangeIcon />,
        headingTitle: "Secure Data Exchange",
        className: "pay-sec-proprietary-gateway",
        content: (
            <>
                Our cutting-edge data exchange platform, VaultLink, provides secure and seamless transfer of sensitive information. Simplify data sharing, ensure compliance, and enhance collaboration across teams.
            </>
        )
    },
    {
        wrapper: "0.0.2",
        frameIcon: <MerchantIntegrationIcon />,
        headingTitle: "Integrations",
        className: "pay-sec-responsive-support",
        content: (
            <> 
                NST Gateway offers integration solutions from a basic eCommerce configuration to an advanced feature-rich set-up with sophisticated fraud prevention and management tools.
            </>
        )
    },
    {
        wrapper: "0.0.3",
        frameIcon: <MerchantTunnelGatewayIcon />,
        headingTitle: "Tunnel Gateway",
        className: "pay-sec-reliable-security",
        content: (
            <>
                Our proprietary gateway, Tunl, is designed to easily integrate payments and technology. Developers can leverage our out-of-the-box platform or integrate our APIs.
            </>
        )
    },
];

export const merchantSolutionNServices = [
    {
        key: "surcharging",
        title: "Surcharging",
        description: "Offset the cost of accepting credit cards with an industry compliant surcharge program. We stay ahead of technology to make sure you are equipped with compatible, compliant POS payment processing software."
    },
    {
        key: "cash_discount",
        title: "Cash Discount",
        description: "NST Gateway is proud to offer an industry compliant cash discount program providing business owners a way to recoup much of the cost associated with accepting credit cards while offering a discount to cash, debit card & check payments."
    },
    {
        key: "multi_currency",
        title: "Multi-Currency",
        description: "Multi-currency pricing allows you to price goods and charge customers in international currencies while receiving deposits in USD. With NST Gateway's MCP program you can capture additional customers by offering payment acceptance in 70+ currencies."
    },
    {
        key: "fraud_prevention",
        title: "Fraud Prevention",
        description: "NST Gateway offers protection from fraud and unwarranted chargebacks with a technology-driven strategy to fight chargebacks and recover lost revenue."
    },
    {
        key: "gift_loyalty_points",
        title: "Gift and Loyalty Points",
        description: "Gift and Loyalty Programs are a great way to boost foot traffic and customer engagement. NST Gateway's gift and loyalty program is ideal for small to medium-sized businesses looking to increase revenue with an additional product and gain loyal or new customers."
    }
];
