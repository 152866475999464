import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import './pageNotFound.css';

const PageNotFound = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="nst-gate-page-not-found">
            <div className="content-container">
                <h1 className="error-code">404</h1>
                <h2 className="error-message">The page you requested was not found.</h2>
                <div className="buttons-container">
                    <Button
                        onClick={handleGoBack}
                        variant="contained"
                        className="link-button primary-btn"
                    >
                        Go Back
                    </Button>
                    <NavLink to="/" className="home-link">
                        <Button
                            variant="outlined"
                            className="link-button secondary-btn"
                        >
                            Go to Home Page
                        </Button>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
