import React from 'react';
import { LogoMainNSTGateway } from 'assests/Home';
import MainForm from './MainForm';

const SectionFormFill = () => {
    return (
        <section className="form-fill-section-main">
            <div className='container'>
                <div className="form-fill-main-container">
                    <div className="form-fill-out-main-form">
                        <div className="form-fill-main-wrapper">
                            <div className="nst-gateway-logo-main">
                                <LogoMainNSTGateway />
                            </div>
                            <div className="form-fill-main-heading-content">
                                <h5>Fill out this form and we will get back to you as soon as possible</h5>
                            </div>
                            <div className="form-fill-input-type">
                                <MainForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionFormFill;