import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

interface AccordionMaterailUiProps {
    expandIcon?: any;
    title: string;
    description: string;
    index: number;
    expandedPanel: string | false;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

export default function AccordionMaterailUi({
    index,
    expandIcon,
    title,
    description,
    expandedPanel,
    handleChange,
}: Readonly<AccordionMaterailUiProps>) {
    const panelId = `panel${index + 1}`;

    return (
        <Accordion
            defaultExpanded={index === 0}
            expanded={expandedPanel === panelId}
            onChange={handleChange(panelId)}
        >
            <AccordionSummary
                expandIcon={expandIcon || <ExpandMoreIcon />}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>{description}</AccordionDetails>
        </Accordion>
    );
}

