import AddIcon from '@mui/icons-material/Add';
import { Button, Modal } from '@mui/material';
import AccordionMaterailUi from 'components/Accordian/AccordianMaterialUi';
import BusinessForm from 'components/BusinessForm/BusinessForm';
import ContentSubSection from 'components/Home/Sections/ContentSubSection';
import { merchantSolutionNServices, subSectionsIntegratePayment } from 'content/merchantProcessingSolution';
import { useState } from 'react';
import "./MerchantProcessingSolutions.css";
import AccordianImg from 'assests/Home/accordian_img.png'
import PaymentImg from 'assests/Home/payment_img.png'
import { FlexibilityConvenience, SecurityReliability, SimplicityAccess } from 'assests/Home';

const MerchantProcessingSolutionsPage = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [expandedPanel, setExpandedPanel] = useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedPanel(isExpanded ? panel : false);
    };
    return (
        <>
            <div className='nst-gate-page'>
                <div className="mps-top">
                    <div className='container'>
                        <div className="mps-content">
                            <h1>Merchant Processing Solutions</h1>
                            <p>Business Problems are Complicated. Payment Solutions Shouldn't Be. Our merchant processing services allow merchants to securely accept transactions wherever they occur.</p>
                            <div className="navigate-use-btn" >
                                <Button className='get-started-btn' onClick={handleOpen}>Get a qoute</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='solution-modal'
                >
                    <BusinessForm title='Send a Message' isModal={true} handleCancel={handleClose} />
                </Modal>
            </div>
            <div className="mps-solutions-container">
                <div className='container'>
                    <div className="mps-solutions-top">
                        <h1>
                            Solutions and services designed to help merchants grow.
                        </h1>
                    </div>
                    <div className="mps-solutions-parts-container">
                        <div className="mps-solutions-parts-left">
                            {
                                merchantSolutionNServices.map((item, index) => {
                                    return (
                                        <div className="mps-solutions-parts-div" key={item.key}>
                                            <div className="mps-solutions-parts-content">
                                                <AccordionMaterailUi index={index} title={item.title} description={item.description} expandIcon={<AddIcon color='primary' />} expandedPanel={expandedPanel} handleChange={handleChange} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='mps-solutions-parts-right'>
                            <div className='mps-solutions-parts-img-wrap'>
                                <img src={AccordianImg} alt='AccordianImg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mps-info-container">
                <div className='container'>

                    <div className="mps-info-content">
                        <div className="mps-info-left-column">
                            <div className="mps-info-header">
                                <h2>Flexible and convenient payments on the go with our mobile payments solutions</h2>
                            </div>
                            <div className='mps-info-left-img-wrap'>
                                <img src={PaymentImg} alt="Placeholder" />
                            </div>
                        </div>
                        <div className="mps-info-right-column">
                            <div className="mps-info-column">
                                <h4><span><SimplicityAccess /></span>Simplicity and Access</h4>
                                <p>With on the go access to our credit card merchant services, you can quickly and easily check the status of your business no matter where you are. Easily measure and track your business with access to dashboard reporting, user management,technical support, and more.</p>
                            </div>
                            <div className="mps-info-column">
                                <h4><span><SecurityReliability /></span>Security and Reliability</h4>
                                <p>Built with security in mind, our mobile solution supports swiped, keyed, and contactless transactions through practically any smartphone or tablet. All of our systems are EMV compliant to meet the highest global standards. We think about security and compliance so all you have to do is plug it in.</p>
                            </div>
                            <div className="mps-info-column">
                                <h4><span><FlexibilityConvenience /></span>Flexibility and Convenience</h4>
                                <p>Our flexible mobile payment solutions can be easily integrated with other point-of-sale systems creating an end-to-end solution for merchants. From delivery and personal services to contractors and repair shops, an mPOS solution offers flexibility and convenience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mps-innovation-container">
                <div className='container'>
                    <div className="mps-innovation-header">
                        <h1>Innovative technology to easily integrate payments</h1>
                    </div>
                    <div className="payment-business-card">
                        {subSectionsIntegratePayment && (subSectionsIntegratePayment || [])?.map((card) => (
                            <ContentSubSection
                                key={card.headingTitle}
                                wrapperId={card.wrapper}
                                FrameIcon={card.frameIcon}
                                headingTitle={card.headingTitle}
                                content={card.content}
                                useClassName={card.className}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MerchantProcessingSolutionsPage;