function supportsCrypto() {
    return !!window.crypto && !!crypto.subtle && !!window.TextEncoder;
}

export const hashPassword = async (password: string) => {
    try {
        if (supportsCrypto()) {
            const encoder = new TextEncoder();
            const data = encoder.encode(password);
            const hashBuffer = await crypto.subtle.digest("SHA-256", data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
            return hashHex;
        } else {
            return "";
        }
    } catch (error) {
        console.log("Error hashing password: ", error);
        return "";
    }
};
