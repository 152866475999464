import { BadgeLoginBlueIcon, BadgeSignUpBlueIcon } from "assests/Home";
import { CONSTANT_ROUTES } from "constants/ConstantRoutes";

export const NSTGatewayHeaderNonAuthLink = [
    {
        key: "0.1",
        page: "Home",
        className:"page-home",
        tab:"home",
        href:CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.INITIAL_APP
    },
    {
        key: "0.2",
        page: "Solution",
        className:"page-solution",
        tab:"solutions",
        href:CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
    },
    {
        key: "0.3",
        page: "Company",
        className:"page-company",
        tab:"company",
        href:CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.COMPANY
    },
    {
        key: "0.4",
        page: "Contact Us",
        className:"page-contact-us",
        tab:"contact_us",
        href:CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.CONTACT_US
    },
    {
        key: "0.5",
        page: "Sign Up",
        Icon: (
            <BadgeSignUpBlueIcon />
        ),
        className:"page-signup",
        tab:"signup",
        href:CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP
    },
    {
        key: "0.6",
        page: "Login",
        Icon: (
            <BadgeLoginBlueIcon />
        ),
        className:"page-login",
        tab:"login",
        href:CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.LOGIN
    },
];