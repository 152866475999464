import { BestInClass, PersonalizedSupport, ProprietaryTechnology, ValueAddedSolutions } from 'assests/Home';
import TrustedPartner from 'assests/Home/trusted_prtner.png';
import GetStarted from 'components/GetStarted/GetStarted';
import ContentSubSection from 'components/Home/Sections/ContentSubSection';
import './Company.css';
import { subSectionsCompanyValue } from 'content/company';

const CompanyPage = () => {
    const companyImprovementItem = [
        { key: 1, img: <BestInClass />, title: 'Best-in-class tools' },
        { key: 2, img: <PersonalizedSupport />, title: 'Personalized support' },
        { key: 3, img: <ProprietaryTechnology />, title: 'Proprietary technology' },
        { key: 4, img: <ValueAddedSolutions />, title: 'Value-added solutions' },
    ];

    return (
        <div className='nst-gate-page'>
            <div className="company-container">
                <div className='container'>
                    <div className="company-content">
                        <h1 className="company-title"><span>Small company team. <br /></span> Large company capabilities.</h1>
                        <p className="company-subtitle">NST Gateway provides first-class support in real time, while delivering competitive, innovative products and services.</p>
                    </div>
                </div>
            </div>
            <div className="company-values-container">
                <div className='container'>
                    <div className="company-values-top">
                        <h2>Our Company Values</h2>
                    </div>
                    <div className="company-values-parts-container">
                        <div className="payment-business-card">
                            {subSectionsCompanyValue && (subSectionsCompanyValue || [])?.map((card) => (
                                <ContentSubSection
                                    key={card.headingTitle}
                                    wrapperId={card.wrapper}
                                    FrameIcon={card.frameIcon}
                                    headingTitle={card.headingTitle}
                                    content={card.content}
                                    useClassName={card.className}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="company-trusted-partner-container">
                    <div className='container'>
                        <div className='company-trusted-partner-wrap'>
                            <div className="company-trusted-partner-left-column">
                                <h3 className="company-trusted-partner-title">A Trusted Partner</h3>
                                <p className="company-trusted-partner-text">Payments made simple</p>
                                <p className="company-trusted-partner-text">We're business owners too so we know how important it is to get paid. Since 1997, we've helped thousands of businesses of all types and sizes accept credit cards easily, while keeping it affordable, secure and forward-thinking.</p>
                                <p className="company-trusted-partner-text">Our company values of integrity, innovation and accessibility can been felt throughout the company in the work we do for our merchants and our partners day in and day out.</p>
                            </div>
                            <div className="company-trusted-partner-right-column">
                                <div className='company-trusted-partner-right-img-wrap'>
                                    <img className="company-trusted-partner-image" src={TrustedPartner} alt="meeting_persons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='get-started-origin'>
                <GetStarted />
            </div>
            <div className="company-improvements-container">
                <div className='container'>
                    <div className='company-improvements-wrap'>
                        <div className="company-improvements-top">
                            <h2 className="company-improvements-title">Everything You Need to Run Your Business with Ease</h2>
                        </div>
                        <div className="company-improvements-bottom">
                            <div className="company-improvements-grid">
                                {
                                    companyImprovementItem.map((item) => {
                                        return (
                                            <div className="company-improvements-item" key={item.key}>
                                                <div className="company-improvements-icon">{item.img}</div>
                                                <p>{item.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyPage;