import './Home.css'
import { Section01 } from 'components/Home/Sections/Section01';
import { SectionMain } from 'components/Home/Sections/SectionMain';
import { ContentSection } from 'components/Home/Sections/ContentSection';
import TrustedPartnerSection from 'components/Home/Sections/TrustedPartnerSection';
import SectionPayment from 'components/Home/Sections/SectionPayment';
import SectionFormFill from 'components/Home/Sections/SectionFormFill';
import SectionImgContent from 'components/Home/Sections/SectionImgContent';

const HomePage = () => {
    return (
        <div className="nst-gateway-home-page-main-wrapper">
            <SectionMain />
            <Section01 />
            <ContentSection />
            <TrustedPartnerSection />
            <SectionPayment />
            <SectionFormFill />
            <SectionImgContent />
        </div>
    );
}

export default HomePage;