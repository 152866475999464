import { DownloadIcon, ShopifyIcon, WoocommerceIcon } from "assests/Home";
import ImgGirlShopping from 'assests/Home/pos_img.png';
import ImgBussinessIntegartedChart from 'assests/Home/seed_img.png';
import ImgWooBusiness from 'assests/Home/woocommerce.png';
import ImgShopify from 'assests/Home/shopify.png'

export const StoreCardInfo = [
    {
        store: "0.2.1",
        className: "card-sec-payment-on-web-store",
        mainWrapperHeaderBlue: "Payments on Web Stores",
        imgMain: ImgWooBusiness,
        imgSmallImg:ImgShopify,
        cardImgClassName: "img-card-shopify-woobusiness",
        cardContentBtnClassName: "card-cntn-btn-payment-on-web-store",
        imgAltText: "ImgShopifyWooBusiness",
        mainHeading: "Payments on Web Stores",
        content: "Customers can use their credit or debit cards right on the Web Store Cash-out page. Merchant receive payments in digital tokens instantaneously to their NST Gateway account.",
        icon1: <WoocommerceIcon/>,
        textOnButton01: "Get Woocommerce plugin",
        btn01Href:'https://bixsystem.com',
        icon2: <ShopifyIcon/>,
        textOnButton02: "Shopify Plugin (Comming soon)",
        btn02Href:"#"
    },
    {
        store: "0.2.2",
        className: "card-sec-payments-in-stores",
        cardContentBtnClassName: "card-cntn-btn-payments-in-stores",
        mainWrapperHeaderBlue: "Payments in Stores",
        imgMain: ImgGirlShopping,
        cardImgClassName: "img-card-girl-shopping",
        imgAltText: "girlImageAfterShopping",
        mainHeading: "Integrated Point of Sale (POS) System",
        content: "NST Gateway System has several types of POS Stations: POS Station with double monitor, PC Station with touch screen, and tablet. POS System for the full Station supports also back-office functions in stores or in headquarters with multiple stores. Besides payments over-the-counter, it also supports staff administration, inventory management, business transactions with suppliers and vendors, inventory planning, scheduling, and administration.",
        icon1: <DownloadIcon/>,
        textOnButton01:"",
        btn01Href : '/BIXPayments_NSTPortal.pdf',
        btn02Href:"#"
    },
    {
        store: "0.2.3",
        className: "card-sec-integrated-business-system",
        cardContentBtnClassName: "card-cntn-btn-integrated-business-system",
        mainWrapperHeaderBlue: "Integrated Business System",
        imgMain: ImgBussinessIntegartedChart,
        cardImgClassName: "img-card-business-integrated-chart",
        imgAltText: "girlImageAfterShopping",
        mainHeading: "Seed-to-Sale Tracking System",
        content: "NST Gateway System integrates businesses with different roles in the supply chain into a full business information system providing registration of all business entities, tracking of production and distribution of their products, management and optimization of inventory, tracking sales and customer deliveries, and quality assurance information. The system provides rich reporting of different types of events and transactions in the integrated NST Gateway System.",
        icon1: <DownloadIcon/>,
        textOnButton01:"Download Brochure",
        btn01Href:'/BIXPayments_NSTPortal.pdf',
        btn02Href:"#"
    },
]