export const CONSTANT_ROUTES = {
    NST_GATEWAY_APP_MAIN: {
        ROUTE_AUTH: {
        },
        ROUTE_UNAUTH: {
            NAVIGATION: {
                INITIAL_APP: '/',
                HOME: "/",
                COMPANY: '/company',
                CONTACT_US: '/getstarted',
                MERCHANT_SOLUTION: '/merchantprocessingsolutions',
            },
            USER :{
                SIGNUP:'/signup',
                LOGIN:'/login'
            }
        },
    },
    UNKNOWN: {
        PAGE_NOT_FOUND: "*"
    },
}
