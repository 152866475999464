import React, { ReactElement, ReactNode } from 'react';

interface ContentSubSectionProps {
    wrapperId?: string | null;
    FrameIcon: ReactElement<any, any>;
    headingTitle: string;
    content: ReactNode;
    useClassName: string;
}

const ContentSubSection = ({ wrapperId, FrameIcon, headingTitle, content, useClassName }: ContentSubSectionProps) => {
    return (
        <div className={`business-card-main ${useClassName}`} id={wrapperId ?? undefined}>
            <div className="business-frame-icon">
                {FrameIcon}
            </div>
            <div className='business-card-content'>
                <div className="business-card-main-heading-title">
                    <h4>{headingTitle}</h4>
                </div>
                <div className="business-card-para">
                    <p>{content}</p>
                </div>
            </div>
        </div>
    );
}

export default ContentSubSection;
