import { BadgeInstagramIcon, BadgeLinkedInIcon, BadgeXTwitterIcon } from "assests/Home"

export const nstGatewayHeading = [
    {
        className: "nst-gateway-heading-navigation",
        heading: "Navigation",
        subSections: [
            {
                nameLink: "Home",
                href: "/"
            },
            {
                nameLink: "Solution",
                href: "/merchantprocessingsolutions"
            },
            {
                nameLink: "Company",
                href: "/company"
            },
        ],
    },
    {
        className: "nst-gateway-heading-support",
        heading: "Support",
        subSections: [
            {
                nameLink: "Policy",
                href: "#"
            },
            {
                nameLink: "FAQs",
                href: "#"
            },
        ],
    },
    {
        className: "nst-gateway-heading-solutions",
        heading: "Solutions",
        subSections: [
            {
                nameLink: "Surcharging",
                href: "#"
            },
            {
                nameLink: "Cash Discount",
                href: "#"
            },
            {
                nameLink: "Fraud Prevention",
                href: "#"
            },
            {
                nameLink: "Gift and Loyalty Points",
                href: "#"
            },
            {
                nameLink: "Multi-Currency",
                href: "#"
            },
        ]
    },
]

export const nstSocialMediaRef = [
    {
        key: "0",
        link: "#", // this link can be nst media link
        mediaIcon: <BadgeLinkedInIcon />
    },
    {
        key: "1",
        link: "#", // this link can be nst media link
        mediaIcon: <BadgeInstagramIcon />
    },
    {
        key: "2",
        link: "#", // this link can be nst media link
        mediaIcon: <BadgeXTwitterIcon />
    },
]