import { Alert, Snackbar } from "@mui/material";
import { ISnackBarStatus } from "interfaces/notifcation";

interface ISnackbarComponentProps {
    open: boolean;
    setSnackbar: (value: { open: boolean, message: string, status: ISnackBarStatus }) => void;
    status: ISnackBarStatus;
    message: string;
}

const SnackbarComponent = ({ open, setSnackbar, message, status }: ISnackbarComponentProps) => {
    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', status: 'success' });
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            key={'top right'}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
        >
            <Alert
                onClose={handleCloseSnackbar}
                severity={status}
                variant="filled"
                sx={{ width: '100%', whiteSpace: 'pre-line' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarComponent;