import { CompanyAccessibilityIcon, CompanyInnovationIcon, CompanyIntegrityIcon } from "assests/Home";

export const subSectionsCompanyValue = [
    {
        wrapper: "0.0.1",
        frameIcon: <CompanyIntegrityIcon />,
        headingTitle: "Integrity",
        className: "pay-sec-proprietary-gateway",
        content: (
            <>
                We believe people are the heart of every successful business. Our company may be forward-thinking with our tech, but we're old school with our support.
            </>
        )
    },
    {
        wrapper: "0.0.2",
        frameIcon: <CompanyInnovationIcon />,
        headingTitle: "Innovation",
        className: "pay-sec-responsive-support",
        content: (
            <> 
                Innovation is critical to growth so we leave no stone unturned in engineering faster, better solutions to help our Merchants and Partners grow their business.
            </>
        )
    },
    {
        wrapper: "0.0.3",
        frameIcon: <CompanyAccessibilityIcon />,
        headingTitle: "Accessibility",
        className: "pay-sec-reliable-security",
        content: (
            <>
                From our platforms, to our products and private sales coaching, we're here to ensure you get the tools and support you need, when you need it.
            </>
        )
    },
];
