import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
    const { pathname } = useLocation(); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleLogoClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return { handleLogoClick };
};

export default useScrollToTop;
