import axios, { AxiosResponse } from "axios";
import { getLocalStorageItem } from "utils/local-storage";

type HttpMethod = "get" | "post" | "put" | "delete";

interface ApiError extends Error {
    response?: AxiosResponse;
}

export const executeApi = async <T>(method: HttpMethod, url: string, data?: object, token?: boolean): Promise<T> => {
    try {
        let config = { headers: {} };
        if (!token) {
            config = {
                headers: { Authorization: `Bearer ${getLocalStorageItem("token")}` },
            };
        }
        config.headers = { "Access-Control-Allow-Origin": "*" };
        const requestConfig = {
            method: method,
            url: url,
            data: data,
            ...config,
        };
        const response: AxiosResponse<T> = await axios(requestConfig);
        return response.data;
    } catch (error) {
        const err = error as ApiError;
        return Promise.reject(err);
    }
};
