import { AddressIcon, LogoMainNSTGateway, PhoneCallingIcon } from "assests/Home";
import { nstGatewayHeading, nstSocialMediaRef } from "content/Footer";
import { Link, NavLink } from "react-router-dom";
import useScrollToTop from "utils/useScrollToTop";
import "./Footer.css";

const Footer = () => {
    const addressMapLink =
        "https://www.google.com/maps/place/1968+S+Coast+Hwy+%232851,+Laguna+Beach,+CA+92651,+USA/@33.5278692,-117.7723779,17z/data=!3m1!4b1!4m5!3m4!1s0x80dce5a7a61530f5:0x4ff1f10db18ce1f2!8m2!3d33.5278692!4d-117.769803?entry=ttu&g_ep=EgoyMDI0MDkzMC4wIKXMDSoASAFQAw%3D%3D";
    const { handleLogoClick } = useScrollToTop();

    return (
        <footer>
            <div className="nst-gateway-get-in-touch-main-container">
                <div className="container">
                    <div className="nst-gateway-footer-wrap">
                        <div className="nst-footer-top">
                            <div className="nst-gateway-contact-us-details-content-section-left">
                            <div className="nst-gateway-content-upper-section">
                                    <div className="nst-gateway-ready-to-accept-payment-heading-content">
                                        <h4>Ready to accept payments anytime, anywhere?</h4>
                                    </div>
                                    <div className="nst-gateway-getstarted-hyperlink">
                                        <div className="navigate-use-btn">
                                            <Link className="get-started-btn " to="/signup">
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="nst-gateway-logo-main">
                                    <Link to="/" onClick={handleLogoClick}>
                                        <LogoMainNSTGateway />
                                    </Link>
                                </div>
                                {/* <div className="nst-gateway-on-social-media">
                                    {nstSocialMediaRef ? (
                                        nstSocialMediaRef.map((media, index) => (
                                            <NavLink key={media.key} to={media.link}>
                                                {media.mediaIcon}
                                            </NavLink>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </div> */}
                                {/* <div className="nst-gateway-contact-us-add-call-sec">
                                    <div className="nst-gateway-contact-us-address">
                                        <div className="nst-gateway-contact-us-address-badge-icon">
                                            <AddressIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-our-address">
                                            <Link style={{ color: "white" }} target="_blank" to={addressMapLink}>
                                                1968 S. Coast Hwy #2851 Laguna Beach, CA 92651
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="nst-gateway-contact-us-phone-calling">
                                        <div className="nst-gateway-contact-us-phone-calling-badge">
                                            <PhoneCallingIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-call-us-on">
                                            <a href="tel:+18006832977" className="phone-number-link">
                                                (800) 683-2977
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="nst-gateway-contact-us-details-content-section-right">
                                {/* <div className="nst-gateway-content-upper-section">
                                    <div className="nst-gateway-ready-to-accept-payment-heading-content">
                                        <h4>Ready to accept payments anytime, anywhere?</h4>
                                    </div>
                                    <div className="nst-gateway-getstarted-hyperlink">
                                        <div className="navigate-use-btn">
                                            <Link className="get-started-btn " to="/signup">
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="nst-gateway-content-below-support-link-section">
                                    <div className="columns-container">
                                        {nstGatewayHeading.map((section, index) => (
                                            <div key={section.heading} className="column">
                                                <h4 className={section.className}>{section.heading}</h4>
                                                <ul>
                                                    {section.subSections.map((subSection, i) => (
                                                        <li key={subSection.nameLink}>
                                                            {index === 0 ? (
                                                                <NavLink key={subSection.href} to={subSection.href}>
                                                                    {subSection?.nameLink}
                                                                </NavLink>
                                                            ) : (
                                                                <Link key={subSection.href} to={subSection.href}>
                                                                    {subSection?.nameLink}
                                                                </Link>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="nst-gateway-contact-us-add-call-sec">
                                    <div className="nst-gateway-contact-us-address">
                                        <div className="nst-gateway-contact-us-address-badge-icon">
                                            <AddressIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-our-address">
                                            <Link style={{ color: "white" }} target="_blank" to={addressMapLink}>
                                                1968 S. Coast Hwy #2851 Laguna Beach, CA 92651
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="nst-gateway-contact-us-phone-calling">
                                        <div className="nst-gateway-contact-us-phone-calling-badge">
                                            <PhoneCallingIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-call-us-on">
                                            <a href="tel:+18006832977" className="phone-number-link">
                                                (800) 683-2977
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="nst-footer-bottom">
                            <div className="nst-gateway-copy-right-notice-content">
                                <div className="copyright-notice-org-text">
                                    <span>© 2024 NST Gateway Payments. All Rights Reserved.</span>
                                </div>
                                <div className="nst-gateway-on-social-media">
                                    {nstSocialMediaRef ? (
                                        nstSocialMediaRef.map((media, index) => (
                                            <NavLink key={media.key} to={media.link}>
                                                {media.mediaIcon}
                                            </NavLink>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
