import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Company from './pages/Company/Company'
import GetStarted from './pages/GetStarted/GetStarted'
import Home from './pages/Home/Home'
import MerchantProcessingSolutions from './pages/MerchantProcessingSolutions/MerchantProcessingSolutions'
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import MainLayout from 'components/MainLayout/MainLayout';
import { CONSTANT_ROUTES } from 'constants/ConstantRoutes';
import SignUp from 'pages/Auth/SignUp/SignUp';
import Login from 'pages/Auth/Login/Login';

const ScrollToTop = () => {
  // Fix window load position bug
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const PageRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.INITIAL_APP} element={<MainLayout />}>
          <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.HOME} element={<Home />} />
          <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.COMPANY} element={<Company />} />
          <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.CONTACT_US} element={<GetStarted />} />
          <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION} element={<MerchantProcessingSolutions />} />
          <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP} element={<SignUp />} />
          <Route path={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.LOGIN} element={<Login />} />
        </Route>
        <Route path={CONSTANT_ROUTES.UNKNOWN.PAGE_NOT_FOUND} element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default PageRoutes;