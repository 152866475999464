import React from 'react';
import StoresCards from './StoresCards';
import { StoreCardInfo } from 'content/Home/SectionImgContent';

const SectionImgContent = () => {
    return (
        <section className="business-stores-content">
            <div className='container'>
                <div className="all-store-cards">
                    {StoreCardInfo && (StoreCardInfo || [])?.map((card, index) => (
                        <StoresCards
                            key={card.mainHeading}
                            cardStoredId={card.store}
                            mainWrapperHeaderBlue={card.mainWrapperHeaderBlue}
                            srcImgMain={card.imgMain}
                            srcImgSmall={card.imgSmallImg}
                            imgAltText={card.imgAltText}
                            storeHeaderTitle={card.mainHeading}
                            paraContent={card.content}
                            icon1={card.icon1}
                            textOnButton01={card.textOnButton01}
                            icon2={card.icon2}
                            textOnButton02={card.textOnButton02}
                            useClassName={card.className}
                            useCardContentBtnClassName={card.cardContentBtnClassName}
                            useCardImgClassName={card.cardImgClassName}
                            btn01Href={card.btn01Href}
                            btn02Href={card.btn02Href}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default SectionImgContent